import React, { useEffect, useState } from "react";
import Loadable from "@loadable/component"
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Home/Banner/HomeBanner';


// const Footer = Loadable(() => import("../components/Footer/Footer"))
const OurNetwork = Loadable(() => import("../components/Home/OurNetwork/OurNetwork"))
const ServiceContentOne = Loadable(() => import("../components/AreaGuideDetails/About/About"))
const ServiceContentTwo = Loadable(() => import("../components/AreaGuideDetails/OurMortgage/OurMortgage"))
const WhyUse = Loadable(() => import("../components/WhyUse/WhyUse"))
const Benefits = Loadable(() => import("../components/WhyUse/Benefits"))
const StaticContent = Loadable(() => import("../components/StaticContent/StaticContent"))
const FAQSection = Loadable(() => import("../components/FAQ/FAQSection"))
const GuidesSection = Loadable(() => import("../components/Guides/HandyGuides"))
const StaticContentSidebar = Loadable(() => import("../components/StaticContent/StaticContentSidebar"))
const AccordionModule = Loadable(() => import("../components/Accordion/Accordion"))
const CaseStudySection = Loadable(() => import("../components/CaseStudies/LatestCaseStudies"))
const Accreditations = Loadable(() => import("../components/Accreditations/Accreditations"))
const Brands = Loadable(() => import("../components/AreaGuideDetails/OurBrands/OurBrands"))
const MultiContent = Loadable(() => import("../components/Home/About/MultiRowsContent"))
const Disclaimer = Loadable(() => import("../components/Disclaimer/Disclaimer"))

const About = Loadable(() => import("../components/Home/About/About"))
const NewsInsights = Loadable(() => import("../components/Home/NewsInsights/NewsInsights"))
const GetKnow = Loadable(() => import("../components/Home/GetKnow/GetKnow"))
const FormBlock = Loadable(() => import("../components/Home/FormBlock/FormBlock"))
const CountBlock = Loadable(() => import("../components/Home/CountBlock/CountBlock"))
const GlobalCount = Loadable(() => import("../components/Home/CountBlock/GlobalCount"))
const CountBlockLeftContent = Loadable(() => import("../components/Home/CountBlock/CountBlockLeftContent"))
const BookValuation = Loadable(() => import("../components/Home/BookValuation/BookValuation"))

const HomeModules = (props) => {

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

        const pageurl = typeof window !== 'undefined' ? window.location.pathname : ''
        if (window) {
            window.history.pushState({}, "", pageurl.split("?")[0]);
        }
    }, [])


    return (
        <React.Fragment>
            <SEO title={props.page.Meta_Title} description={props.page.Meta_Description} image={props.page.Banner_Image ? props.page.Banner_Image.url : ''} />


            <div className={props.page.Layout === 'Homepage' ? "home-header" : props.page.Layout === 'Default' ? 'wrapper area-guide-wrapper' : props.page.Layout === 'Staticpage' || props.page.Layout === 'Valuation' || props.page.Layout === 'Background_Image_Layout' || props.page.Layout === 'Calculator_or_Form' ? 'wrapper static-page' : ''}>
                <Header isHomePage={true}/>

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL === 'home' &&
                    <Banner {...props.page} />
                }

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL !== 'home' &&
                    <Banner {...props.page} />
                }


            </div>
            {
                props.modules && props.modules.map((module, i) => {
                    return (
                        <>
                            {module.Intro_Content &&
                                <About {...module} />
                            }

                            {module.Add_Service_Tile &&
                                <GetKnow {...module} />
                            }

                            {module.Add_Stats && renderComponent &&
                                <>
                                    {module.Statistics_Left_Content ?
                                        <CountBlockLeftContent {...module} /> : <CountBlock {...module} />
                                    }
                                </>
                            }

                            {module.Select_Module && module.Select_Module.CTA_1_Link == null &&
                                <FormBlock {...module.Select_Module} />
                            }

                            {module.Select_Module && module.Select_Module.CTA_1_Link &&
                                <BookValuation {...module.Select_Module} />
                            }

                            {module.Latest_News_Heading && module.Latest_News_Intro_Content &&
                                <NewsInsights {...module} />
                            }

                            {module.Add_New_Block &&
                                <>
                                    {module.Add_New_Block.length >= 3 ?
                                        <>
                                            {module.Add_New_Block && module.Add_New_Block.slice(0, 1).map((service, i) => {
                                                return (
                                                    <ServiceContentOne {...service} service_length={module.Add_New_Block.length} />
                                                )
                                            })}
                                            {module.Add_New_Block.length > 1 &&
                                                <ServiceContentTwo {...module} />
                                            }
                                        </> : module.Add_New_Block.length == 2 ?
                                            <ServiceContentTwo {...module} cut="0" /> :
                                            <>
                                                {module.Add_New_Block && module.Add_New_Block.slice(0, 1).map((service, i) => {
                                                    return (
                                                        <ServiceContentOne {...service} service_length={module.Add_New_Block.length} />
                                                    )
                                                })}
                                            </>
                                    }

                                </>
                            }

                            {module.Choose_Module === "Our_Network" &&
                                <OurNetwork {...props} />
                            }

                            {module.Choose_Module === "Why_Use" &&
                                <WhyUse />
                            }

                            {module.Add_Benefit &&
                                <Benefits  {...module} />
                            }

                            {module.Add_Static_Content &&
                                <>
                                    {module.Style == 'With_Sidebar' ?
                                        <StaticContentSidebar layout={props.page.Layout} {...module} {...props.page} count={i} /> : <StaticContent {...module} style={module.Style} count={i} />
                                    }
                                </>
                            }


                            {module.Add_Brand &&
                                <Brands {...module} />
                            }

                            {module.Select_FAQs &&
                                <FAQSection {...module} />
                            }

                            {module.Add_Accordion &&
                                <AccordionModule {...module} />
                            }

                            {module.Select_Guides_here &&
                                <GuidesSection {...module} />
                            }

                            {module.Select_Case_Studies_to_Show &&
                                <CaseStudySection {...module} />
                            }

                            {module.Add_Accreditation &&
                                <Accreditations {...module} />
                            }

                            {module.Stats_Title &&
                                <GlobalCount {...module} />
                            }

                            {module.Add_New_Column && props.page.Layout !== 'Background_Image_Layout' &&
                                <MultiContent {...module} />
                            }

                            {module.Add_Disclaimer_Content &&
                                <Disclaimer {...module} />
                            }
                        </>
                    )
                })
            }
            {/* <OurNetwork/> */}
            <Footer search={props.page.Popular_Search} />
        </React.Fragment>
    )
}
export default HomeModules;
